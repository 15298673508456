import { useAuth0 } from "@auth0/auth0-react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { IServiceConsumer } from "../../interfaces";
import { loggerService, networkService } from "../../services";
import { useConsumerStore } from "../../store";
import { useFormStore } from "../../store/form";
import FieldError from "../shared/components/field-error.component";

import websocketLoggerService from "../../services/websocket-logger";
import useReferralCodeStore from "../../store/referralCode.store";
import { cdtMapping } from "../shared/config";

const phoneRegExp = /^(\+\d{1,3})?\d{8}$/;

interface PatientInfoFormProps {
  setStep: (step: number) => void;
  setUserInfoLog: (userInfoLog: any) => void;
  isRegistered: (isRegistered: boolean) => void;
}

interface IPatientInfo {
  id: string;
  firstName: string;
  lastName: string;
  middleName: string;
  gender: string;
  birthDate: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  mobileNumber: string;
  email: string;
}

const PatientInfoForm: React.FC<PatientInfoFormProps> = ({
  setStep,
  setUserInfoLog,
  isRegistered,
}) => {
  const minDate = moment(new Date("1900/01/01")).format("YYYY-MM-DD");
  const maxDate = moment(new Date()).format("YYYY-MM-DD");
  const { user } = useAuth0();
  const {
    refCode_region,
    refCode_territory,
    refCode_excelsheetid,
    refCode_consentTermsLink,
    refCode_termsOfUseLink,
  } = useReferralCodeStore((state) => ({
    refCode_region: state.refCode_region,
    refCode_territory: state.refCode_territory,
    refCode_excelsheetid: state.refCode_excelsheetid,
    refCode_consentTermsLink: state.refCode_consentTermsLink,
    refCode_termsOfUseLink: state.refCode_termsOfUseLink,
  }));

  const { referralCode } = useFormStore();
  const [isAgree, setIsAgree] = useState<boolean>(false);
  const [isMobileUniqueError, setIsMobileUniqueError] =
    useState<boolean>(false);
  const [isEmailUniqueError, setIsEmailUniqueError] = useState<boolean>(false);
  const [setConsumerState, consumer, setConsumer] = useConsumerStore(
    (state) => [state.setConsumerState, state.consumer, state.setConsumer]
  );
  const navigate = useNavigate();

  const [termsOfUse, setTermsOfUse] = useState(false);
  const [consentTerms, setConsentTerms] = useState(false);
  const [toggleTermsOfUse, setToggleTermsOfUse] = useState(false);
  const [toggleSalesTerms, setToggleSalesTerms] = useState(false);

  const handleToggleTermsOfUse = (e: React.ChangeEvent<HTMLInputElement>) => {
    setToggleTermsOfUse(e.target.checked);
    setIsAgree(e.target.checked);
  };

  useEffect(() => {
    if (!referralCode) {
      navigate("/");
    }
  }, []);

  const isCorporateUser = referralCode?.includes("-");

  const schema = Yup.object().shape({
    CorporateUser: Yup.boolean(),
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    corporateReferralCode: Yup.string().required(
      "Corporate Referral Code is required"
    ),
    ethnicity: Yup.string().required("Ethnicity is required"),
    employeeId: Yup.string().when("CorporateUser", {
      is: true,
      then: Yup.string().required("Employee ID is required"),
    }),
    department: Yup.string().when("CorporateUser", {
      is: true,
      then: Yup.string().required("Department is required"),
    }),
    dateOfBirth: Yup.date()
      .min(new Date("1900/01/01"), "Minimum date limit is 1900/01/01")
      .max(new Date(), "Please enter a valid date.")
      .required("Date of birth is required."),
    gender: Yup.string().required("Gender is required."),
    mobileNumber: Yup.string()
      .required("Mobile Number is required.")
      .matches(phoneRegExp, "Please enter a valid phone number"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    // cards: Yup.string().required("Cards is required"),
    longTermConditions: Yup.array(),
    otherReason: Yup.string().when("longTermConditions", {
      is: (longTermConditions) => longTermConditions.includes("Others"),
      then: Yup.string().required("Please enter other reasons"),
    }),
  });

  useEffect(() => {
    if (!user) return;

    const checkPatientProgram = async (userID) => {
      const userProgram = await networkService.get(
        `/api/patients/${userID as string}/programs/${
          cdtMapping["full-program-name"]
        }`
      );
      console.log(userProgram);

      if (userProgram) {
        navigate("/patient-program-success");
      }
    };

    const returnPatient = async () => {
      const patient: any = await getServiceConsumerByMobileNumber(
        user.phone_number
      );

      // get timezone from browser
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const patientInfoWithTimezone = {
        ...patient,
        timeZone: timezone,
      };

      setConsumer(patientInfoWithTimezone);
      await checkPatientProgram(patientInfoWithTimezone.id);

      if (patient.id) {
        isRegistered(true);

        if (referralCode?.includes("PSA-001")) {
          websocketLoggerService.sendMessage({
            externalUserId: patient.id,
            authenticationId: user.sub,
            eventType: `Navigate`,
            eventSubType: `Navigate to PSA-registration-successful, referralCode: ${referralCode}`,
            eventData: "",
          });
          navigate("/PSA-registration-successful", {
            state: {
              isAlreadyRegistered: true,
            },
          });
        } else {
          setStep(1);
        }
      }

      return patient;
    };
    returnPatient();
  }, [isRegistered, navigate, referralCode, setConsumer, setStep, user]);

  const getServiceConsumerByMobileNumber = async (phoneNumber: string) => {
    const patientMobileNumber: string = phoneNumber
      .replace("+", "%2B")
      .toString();

    try {
      const response: IPatientInfo = await networkService.get(
        `/api/patients?phone=${patientMobileNumber}`
      );

      // console.log(response);
      websocketLoggerService.sendMessage({
        externalUserId: response.id,
        authenticationId: user?.sub,
        eventType: `API request: Get patient by phone number, PUT /api/patients/phone`,
        eventSubType: `check if patient exists in EHR, referralCode: ${referralCode}`,
        eventData: "patient exists in EHR",
      });

      return response;
    } catch (error) {
      // console.log(error);
      websocketLoggerService.sendMessage({
        externalUserId: null,
        authenticationId: user?.sub,
        eventType: `API request: Get patient by phone number, PUT /api/patients/phone`,
        eventSubType: `check if patient exists in EHR, referralCode: ${referralCode}`,
        eventData: "patient does not exist in EHR",
      });
      return {
        id: null,
        firstName: null,
        lastName: null,
        middleName: null,
        gender: null,
        birthDate: null,
        addressLine1: null,
        addressLine2: null,
        zipCode: null,
        mobileNumber: user.phone_number,
        email: null,
        regionName: null,
        territory: null,
        dependants: [],
      };
    }
  };

  const addCdtInfo = async (
    values,
    longTermConditionsCdt,
    PatientId: string
  ) => {
    try {
      await networkService.put(`/api/patients/${PatientId}`, {
        cdts: [
          {
            name: "cdt-cr-demographics",
            multiRecord: false,
            records: [
              {
                fields: [
                  {
                    key: cdtMapping.ethnicity["cdt-field"],
                    value: values.ethnicity,
                  },
                  // {
                  //   key: "cdtf-chascard",
                  //   value: values.cards,
                  // },
                  {
                    key: cdtMapping.employeeid["cdt-field"],
                    value: values.employeeId,
                  },
                ],
              },
            ],
          },
          {
            name: cdtMapping["long-term-condition"]["cdt-name"],
            multiRecord: true,
            records: longTermConditionsCdt,
          },
          {
            name: cdtMapping.cdt_terms["cdt-name"],
            multiRecord: false,
            records: [
              {
                fields: [
                  {
                    key: cdtMapping.cdt_terms["cdtf-consent"],
                    value: toggleTermsOfUse ? "Yes" : "No",
                  },
                  {
                    key: cdtMapping.cdt_terms["cdtf-marketingconsent"],
                    value: toggleSalesTerms ? "Yes" : "No",
                  },
                ],
              },
            ],
          },
        ],
      });

      websocketLoggerService.sendMessage({
        externalUserId: PatientId,
        authenticationId: user?.sub,
        eventType: `API request: PUT /api/patients/${PatientId}`,
        eventSubType: `Add CDT info, referralCode: ${referralCode}`,
        eventData: "added CDT info successfully",
      });
    } catch (error) {
      websocketLoggerService.sendMessage({
        externalUserId: PatientId,
        authenticationId: user?.sub,
        eventType: `API request: PUT /api/patients/${PatientId}`,
        eventSubType: `Add CDT info, referralCode: ${referralCode}`,
        eventData: "Could not add CDT info",
      });
    }
  };

  const addCareTeamId = async (patientId: string) => {
    await networkService
      .post(`/api/patients/${patientId.toString()}/care-team`, {
        participants: [
          {
            id: cdtMapping["care-provider"],
          },
        ],
      })
      .then(() => {
        websocketLoggerService.sendMessage({
          externalUserId: patientId,
          authenticationId: user?.sub,

          eventType: `API request: POST /api/patients/${patientId}/care-team`,
          eventSubType: `Add care team, referralCode: ${referralCode}`,
          eventData: "Care team added successfully",
        });
      })
      .catch((error) => {
        console.log(error);
        websocketLoggerService.sendMessage({
          externalUserId: patientId,
          authenticationId: user?.sub,

          eventType: `API request: POST /api/patients/${patientId}/care-team`,
          eventSubType: `Add care team, referralCode: ${referralCode}`,
          eventData: "Could not add care team member",
        });
      });
  };

  const enrollUser = async (patientId: string) => {
    const enrollmentProgramName: string = cdtMapping["program-name"];
    await networkService.put(
      `/api/patients/${patientId}/programs/${enrollmentProgramName}`,
      {
        assigned: true,
        status: "IN_PROGRESS",
      }
    );
  };

  const handleSubmit = async (values, setSubmitting) => {
    // e.preventDefault()

    const regionName = refCode_region;
    const territoryFromReferralCode = refCode_territory;
    const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const userDetails = {
      firstName: values.firstName,
      lastName: values.lastName,
      middleName: null,
      gender: values.gender,
      birthDate: values.dateOfBirth,
      addressLine1: null,
      addressLine2: null,
      // city: null,
      // state: null,
      zipCode: null,
      country: null,
      mobileNumber: values.mobileNumber,
      email: values.email,
      territory: territoryFromReferralCode,
      // mrn: values.employeeId,
      department: values.department,
      regionName,
      timeZone:
        currentTimeZone === "Asia/Calcutta"
          ? "Asia/Singapore"
          : currentTimeZone,
    };

    const longTermConditionsCdt: any = [];
    values.longTermConditions.map((condition: string) => {
      if (condition !== "Others") {
        const conditionRecord: any = {
          fields: [
            {
              key: "cdtf-icddiagnosis",
              value: JSON.stringify({
                id: condition.split("--")[1],
                "icd-10am_short_description": condition.split("--")[0],
              }),
            },
            {
              key: "cdtf-source_of_diagnosis",
              value: "Self-reported",
            },
          ],
        };
        return longTermConditionsCdt.push(conditionRecord);
      }
      return longTermConditionsCdt;
    });

    try {
      let consumerId;

      if (consumer.id !== null) {
        const res = await networkService.put<{
          token: string;
          patient: IServiceConsumer;
        }>(`/api/patients/${consumer.id}`, userDetails);

        console.log(res);

        addCdtInfo(values, longTermConditionsCdt, consumer.id);
        addCareTeamId(consumer.id);
        consumerId = consumer.id;
      } else {
        const newPatient: IServiceConsumer = await networkService.post(
          `/api/patients`,
          userDetails
        );

        websocketLoggerService.sendMessage({
          externalUserId: newPatient.id,
          authenticationId: user?.sub,
          eventType: "API request: POST Create new patients /api/patients",
          eventSubType: "Create new patient if user does not exist in EHR",
          eventData: "New patient created",
        });

        enrollUser(newPatient.id);
        addCdtInfo(values, longTermConditionsCdt, newPatient.id);
        addCareTeamId(newPatient.id);

        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        const newPatientInfoWithTimezone = {
          ...newPatient,
          timeZone: timezone,
        };
        consumerId = newPatient.id;
        setConsumer(newPatientInfoWithTimezone);
      }

      const formattedlongTermConditions = values.longTermConditions.map(
        (disease) => {
          return disease.split("--")[0];
        }
      );

      let formattedlongTermConditionsString: string =
        formattedlongTermConditions.join("; ");

      if (values.longTermConditions.includes("Others")) {
        const otherReason: string = values.otherReason
          .replace("otherReason", "")
          .toString();

        formattedlongTermConditionsString =
          formattedlongTermConditionsString + "; " + otherReason;
      }

      const targetOptions = [
        "Type 2 Diabetes Mellitus",
        "Essential Hypertension",
        "Hyperlipidaemia, Unspecified",
        "Others",
      ];

      const resultArray = targetOptions.map((option) => {
        if (formattedlongTermConditions.includes(option)) {
          return option;
        } else {
          return "";
        }
      });

      if (values.longTermConditions.includes("Others")) {
        const otherReason: string = values.otherReason
          .replace("otherReason", "")
          .toString();

        resultArray[3] = otherReason;
      }

      const valuesToLog = [
        Date().toLocaleString(),
        values.firstName,
        values.lastName,
        values.corporateReferralCode,
        values.ethnicity,
        values.employeeId,
        values.department,
        values.dateOfBirth,
        values.gender,
        values.mobileNumber,
        values.email,
        // values.cards,
        // formattedlongTermConditionsString,
        resultArray[0],
        resultArray[1],
        resultArray[2],
        resultArray[3],
        consumerId,
      ];

      setUserInfoLog(valuesToLog);
      console.log(valuesToLog);

      const organizationId = cdtMapping.orgid;
      const sheetIdFromReferralCode = refCode_excelsheetid;

      networkService
        .post(`/api/sheets-integration`, {
          organizationId,
          spreadsheetId: sheetIdFromReferralCode,
          sheetName: "Sheet1",
          // primaryKeyColumnIndex: 12,
          endingColumn: "AN",
          dataToStore: [valuesToLog],
        })
        .then((res) => {
          websocketLoggerService.sendMessage({
            externalUserId: consumer.id,
            authenticationId: user?.sub,
            eventType: `Excel sheet integration`,
            eventSubType: "web intake end",
            eventData: "Excel sheet integration request successful",
          });
        })
        .catch((err) => {
          websocketLoggerService.sendMessage({
            externalUserId: consumer.id,
            authenticationId: user?.sub,
            eventType: `Excel sheet integration`,
            eventSubType: "web intake end",
            eventData: "Excel sheet integration request failed",
          });
          console.log(err);
        });

      if (referralCode.includes("PSA-001")) {
        networkService
          .post(`/api/sheets-integration`, {
            organizationId,
            spreadsheetId: refCode_excelsheetid,
            sheetName: "Sheet1",
            primaryKeyColumnIndex: 9,
            endingColumn: "AN",
            dataToStore: [valuesToLog],
          })
          .then((res) => {
            websocketLoggerService.sendMessage({
              externalUserId: consumer.id,
              authenticationId: user?.sub,
              eventType: "API request: POST /api/sheets-integration",
              eventSubType: `sheets-integration, referralCode: ${referralCode}`,
              eventData: "successfully added logs to sheets-integration",
            });
          })
          .catch((err) => {
            loggerService.error(err);
            websocketLoggerService.sendMessage({
              externalUserId: consumer.id,
              authenticationId: user?.sub,
              eventType: "API request: POST /api/sheets-integration",
              eventSubType: `sheets-integration, referralCode: ${referralCode}`,
              eventData: "sheets-integration request failed",
            });
          });
      }

      if (referralCode.includes("PSA-001")) {
        websocketLoggerService.sendMessage({
          externalUserId: consumer.id,
          authenticationId: user?.sub,
          eventType: `Navigate`,
          eventSubType: `Navigate to /PSA-registration-successful, referralCode: ${referralCode}`,
          eventData: "",
        });
        navigate("/PSA-registration-successful");
      } else {
        setStep(1);
      }
    } catch (err) {
      setSubmitting(false);

      if (err.message.includes("email")) {
        setIsEmailUniqueError(true);
        toast.error(err.message);
        websocketLoggerService.sendMessage({
          externalUserId: null,
          authenticationId: user?.sub,
          eventType: "API request: Create new patients: POST /api/patients",
          eventSubType: `Error: ${JSON.stringify(err)}`,
          eventData: "Create new patient failed",
        });
      }
    }
  };

  const initialValues = {
    CorporateUser: isCorporateUser,
    firstName: consumer?.firstName || "",
    lastName: consumer?.lastName || "",
    corporateReferralCode: referralCode?.toUpperCase(),
    ethnicity: "",
    employeeId: consumer?.mrn || "",
    department: "",
    dateOfBirth: moment.utc(consumer?.birthDate).format("YYYY-MM-DD") || "",
    gender: consumer?.gender || "",
    mobileNumber: consumer?.mobileNumber || user?.name || "",
    email: consumer?.email || "",
    // cards: "",
    longTermConditions: [],
    otherReason: "",
  };

  const longTermConditionsOptions = [
    {
      label: "Type 2 Diabetes",
      value: cdtMapping["long-term-condition"]["cdt-field"]["Type 2 Diabetes"],
    },
    {
      label: "High Blood Pressure",
      value:
        cdtMapping["long-term-condition"]["cdt-field"]["High Blood pressure"],
    },
    {
      label: "High Cholesterol",
      value: cdtMapping["long-term-condition"]["cdt-field"].Cholesterol,
    },
    {
      label: "Others",
      value: "Others",
    },
  ];

  return (
    <div className="flex-[2] pb-10 pt-8 pr-32 pl-20 min-h-screen max-lg:w-full bg-[#F6F6FB] max-lg:px-5">
      <div>
        <h1 className="font-bold text-xl">1. Provide Personal Information</h1>
        <main className="mt-2">
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting }) => {
              handleSubmit(values, setSubmitting);
            }}>
            {({ values, setFieldValue, isSubmitting }) => (
              <Form className="flex flex-col gap-4">
                <section className={`flex flex-col gap-0`}>
                  <div className="flex gap-6 items-center max-sm:flex-col max-sm:w-full max-sm:gap-3">
                    <div className="flex-1 max-sm:w-full">
                      <label className="text-sm text-black/60">
                        First Name
                      </label>
                      <Field
                        name="firstName"
                        type="text"
                        className="w-full py-3 px-2 border border-gray-300 focus:border-primary-500 focus:text-primary-800 rounded-md mt-1"
                        placeholder="Enter First Name"
                      />
                      <div className="min-h-[25px] ">
                        <ErrorMessage name="firstName">
                          {(msg) => <FieldError message={msg} />}
                        </ErrorMessage>
                      </div>
                    </div>
                    <div className="flex-1 max-sm:w-full">
                      <label className="text-sm text-black/60">Last Name</label>
                      <Field
                        name="lastName"
                        type="text"
                        className="w-full py-3 px-2 border border-gray-300 focus:border-primary-500 focus:text-primary-800 rounded-md mt-1"
                        placeholder="Enter Last Name"
                      />
                      <div className="min-h-[25px]">
                        <ErrorMessage name="lastName" className="min-h-[20px]">
                          {(msg) => <FieldError message={msg} />}
                        </ErrorMessage>
                      </div>
                    </div>
                  </div>
                  <div className="flex-1 max-sm:w-full">
                    <p className="text-sm text-gray-500">Referral Code</p>
                    <Field
                      type="text"
                      disabled
                      name="corporateReferralCode"
                      placeholder="Referral Code"
                      className="w-full py-3 border border-gray-300 bg-gray-100 rounded-md mt-2 "
                    />
                    <div className="min-h-[30px] pt-2">
                      <div className="min-h-[25px]">
                        <ErrorMessage name="corporateReferralCode">
                          {(msg) => <FieldError message={msg} />}
                        </ErrorMessage>
                      </div>
                    </div>
                  </div>
                  {isCorporateUser ? (
                    <div className="flex gap-6 items-center max-sm:flex-col max-sm:w-full max-sm:gap-3">
                      <div className="flex-1 max-sm:w-full">
                        <label className="text-sm text-black/60">
                          Employee ID
                        </label>
                        <Field
                          type="text"
                          name="employeeId"
                          placeholder="Enter Employee ID"
                          className="w-full py-3 border border-gray-300 rounded-md mt-1 "
                        />
                        <div className="min-h-[25px]">
                          <ErrorMessage name="employeeId">
                            {(msg) => <FieldError message={msg} />}
                          </ErrorMessage>
                        </div>
                      </div>
                      <div className="flex-1 max-sm:w-full">
                        <label className="text-sm text-black/60">
                          Department
                        </label>
                        <Field
                          name="department"
                          type="text"
                          placeholder="Enter Department Name"
                          className="w-full block py-3 mt-1 max-sm:mt-0 border-gray-300 rounded-md disabled:bg-slate-100"></Field>
                        <div className="min-h-[25px]">
                          <ErrorMessage name="department">
                            {(msg) => <FieldError message={msg} />}
                          </ErrorMessage>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <div>
                    <label className="text-sm text-black/60">
                      Personal Email Address
                    </label>
                    <Field
                      name="email"
                      type="email"
                      placeholder="Enter email"
                      onChange={(e) => {
                        setIsEmailUniqueError(false);
                        setFieldValue("email", e.target.value);
                      }}
                      className="w-full py-3 px-2 border border-gray-300 rounded-md mt-1"
                    />
                    <div className="min-h-[25px]">
                      <ErrorMessage name="email">
                        {(msg) => <FieldError message={msg} />}
                      </ErrorMessage>
                      {isEmailUniqueError && (
                        <FieldError message="This email is already registered. Please contact admin at rippleadmin@sheareshealthcare.com.sg" />
                      )}
                    </div>
                  </div>
                  <div className="flex gap-6 items-center max-sm:flex-col max-sm:w-full max-sm:gap-3">
                    <div className="flex-1 max-sm:w-full">
                      <label className="text-sm text-black/60">
                        Mobile Number
                      </label>
                      <Field
                        name="mobileNumber"
                        type="tel"
                        placeholder="+65 0000 0000"
                        disabled
                        onChange={(e) => {
                          setIsMobileUniqueError(false);
                          setFieldValue("mobileNumber", e.target.value);
                        }}
                        className="w-full py-3 bg-gray-100 px-2 border border-gray-300 rounded-md mt-1"
                      />
                      <div className="min-h-[25px]">
                        <ErrorMessage name="mobileNumber">
                          {(msg) => <FieldError message={msg} />}
                        </ErrorMessage>
                        {isMobileUniqueError && (
                          <FieldError message="This mobile is already registered. Please contact admin at rippleadmin@sheareshealthcare.com.sg" />
                        )}
                      </div>
                    </div>
                    <div className="flex-1 max-sm:w-full">
                      <p className="text-sm text-gray-500">Date of Birth</p>
                      <Field
                        type="date"
                        name="dateOfBirth"
                        placeholder="DD/MM/YYYY"
                        min={minDate}
                        max={maxDate}
                        // placeholder="Select Date of Birth"
                        className="w-full py-3 border border-gray-300 rounded-md mt-2 "
                      />
                      <div className="min-h-[25px]">
                        <ErrorMessage name="dateOfBirth">
                          {(msg) => <FieldError message={msg} />}
                        </ErrorMessage>
                      </div>
                    </div>
                  </div>

                  <div className="flex gap-6 items-center max-sm:flex-col max-sm:w-full max-sm:gap-3">
                    <div className="flex-1 max-sm:w-full">
                      <label className="text-sm text-black/60">Gender</label>
                      <Field
                        id="gender"
                        name="gender"
                        type="text"
                        placeholder="Select gender"
                        component="select"
                        className="w-full block py-3 mt-1 max-sm:mt-0 border-gray-300 rounded-md disabled:bg-slate-100">
                        <option className="text-gray-400" value="" disabled>
                          Select gender
                        </option>
                        <option value="MALE">Male</option>
                        <option value="FEMALE">Female</option>
                      </Field>
                      <div className="min-h-[25px]">
                        <ErrorMessage name="gender">
                          {(msg) => <FieldError message={msg} />}
                        </ErrorMessage>
                      </div>
                    </div>
                    <div className="flex-1 max-sm:w-full">
                      <label className="text-sm text-black/60">Ethnicity</label>
                      <Field
                        name="ethnicity"
                        placeholder="Select Ethnicity"
                        component="select"
                        className="w-full block py-3 mt-1 max-sm:mt-0 border-gray-300 rounded-md disabled:bg-slate-100">
                        <option className="text-gray-400" value="" disabled>
                          Select Ethnicity
                        </option>
                        <option value="Chinese">Chinese</option>
                        <option value="Malay">Malay</option>
                        <option value="Indian">Indian</option>
                        <option value="Others">Others</option>
                      </Field>
                      <div className="min-h-[25px]">
                        <ErrorMessage name="ethnicity">
                          {(msg) => <FieldError message={msg} />}
                        </ErrorMessage>
                      </div>
                    </div>
                  </div>
                  {/* <div className="flex-1 max-sm:w-full">
                    <label className="text-sm text-black/60">
                      Which of the following subsidy cards do you have?
                    </label>
                    <Field
                      name="cards"
                      placeholder="Select Card"
                      component="select"
                      className="w-full block py-3 mt-1 max-sm:mt-0 border-gray-300 rounded-md disabled:bg-slate-100">
                      <option value="" disabled>
                        Select Card
                      </option>
                      <option value="CHAS Blue">CHAS Blue</option>
                      <option value="CHAS Orange">CHAS Orange</option>
                      <option value="CHAS Green">CHAS Green</option>
                      <option value="Merdeka Card">Merdeka Card</option>
                      <option value="Pioneer Generation Card">
                        Pioneer Generation Card
                      </option>
                      <option value="CR Not aware of">
                        I don't know/None of the above
                      </option>
                    </Field>
                    <ErrorMessage name="cards">
                      {(msg) => <FieldError message={msg} />}
                    </ErrorMessage>
                  </div> */}
                  <div
                    role="group"
                    aria-labelledby="checkbox-group"
                    className="flex-1 max-sm:w-full">
                    <div className="text-sm text-black/60">
                      Which of the following long-term conditions do you have?{" "}
                      <br /> Select all that apply. Please leave blank if none
                      applies.
                    </div>
                    {/* <div className="min-h-[25px]"> */}
                    <ErrorMessage name="longTermConditions">
                      {(msg) => <FieldError message={msg} />}
                    </ErrorMessage>
                    {/* </div> */}

                    {longTermConditionsOptions.map((option) => (
                      <div className="mt-4" key={option.value}>
                        <label className="flex gap-4 items-center">
                          <Field
                            type="checkbox"
                            name="longTermConditions"
                            placeholder="Select Long term conditions"
                            value={option.value}
                            className="w-6 h-6 text-primary-500 bg-white border-ripple-gray-500 rounded focus:ring-primary-500 focus:ring-2"
                            // onChange={(e) => {
                            //   console.log(e.target.checked);
                            // }}
                          />
                          <div>{option.label}</div>
                        </label>
                      </div>
                    ))}

                    {values.longTermConditions.includes("Others") && (
                      <>
                        <label>
                          <Field
                            className="w-full mt-4 py-3 px-2 border border-gray-300 rounded-md"
                            type="text"
                            placeholder="Please specify"
                            name="otherReason"
                          />
                        </label>
                        <ErrorMessage name="otherReason">
                          {(msg) => <FieldError message={msg} />}
                        </ErrorMessage>
                      </>
                    )}
                  </div>
                </section>
                <div className="border border-primary-700 bg-primary-50 py-8 px-4 rounded">
                  <div>
                    Please click and read{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={refCode_termsOfUseLink}
                      className="text-primary-800"
                      onClick={() => {
                        setTermsOfUse(true);
                      }}>
                      Ripple® programme terms of use
                    </a>
                    {" and "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={refCode_consentTermsLink}
                      className="text-primary-800"
                      onClick={() => {
                        setConsentTerms(true);
                      }}>
                      consent terms
                    </a>{" "}
                    and select the checkbox below to proceed with the
                    registration.
                    <label className="flex gap-4 mt-4 w-fit">
                      <input
                        type="checkbox"
                        placeholder="Select Long term conditions"
                        onChange={(e) => handleToggleTermsOfUse(e)}
                        className="w-6 h-6 text-primary-500 bg-white border-ripple-gray-500 rounded focus:ring-primary-500 focus:ring-2"
                        disabled={!termsOfUse || !consentTerms}
                      />
                      <div
                        className={`text-sm text-black ${
                          !termsOfUse || !consentTerms
                            ? "text-opacity-40"
                            : "cursor-pointer"
                        }`}>
                        I agree to the Azodha ® terms of use and consent terms
                      </div>
                    </label>
                    <div className="text-xs py-2 text-black text-opacity-60">
                      Optional :
                    </div>
                    <label className="flex gap-4 w-fit">
                      <input
                        type="checkbox"
                        placeholder="Select Long term conditions"
                        onChange={(e) => {
                          setToggleSalesTerms(e.target.checked);
                        }}
                        className="w-6 h-6 text-primary-500 bg-white border-ripple-gray-500 rounded focus:ring-primary-500 focus:ring-2"
                        disabled={!termsOfUse || !consentTerms}
                      />
                      <div
                        className={`text-sm text-black ${
                          !termsOfUse || !consentTerms
                            ? "text-opacity-40"
                            : "cursor-pointer"
                        }`}>
                        I agree to receive Azodha programme sales & marketing
                        materials.
                      </div>
                    </label>
                  </div>
                </div>
                <button
                  type="submit"
                  disabled={!isAgree}
                  className={`w-max px-12 py-3 mt-4 text-white rounded font-bold ${
                    isAgree
                      ? "bg-primary-500"
                      : "bg-gray-400 cursor-not-allowed"
                  }`}>
                  {isSubmitting ? (
                    <div className="flex gap-2 items-center">
                      <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></div>
                      <div>Submitting...</div>
                    </div>
                  ) : (
                    "Submit"
                  )}
                </button>
              </Form>
            )}
          </Formik>
        </main>
      </div>
    </div>
  );
};
export default PatientInfoForm;
