import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useConsumerStore } from "../../store";
import useReferralCodeStore from "../../store/referralCode.store";
import Backdrop from "../shared/components/backdrop.component";
import { cdtMapping } from "../shared/config";

const CancelBookingSuccess: React.FC = () => {
  const { state } = useLocation();
  const { user } = useAuth0();
  const allReferralCodes = useReferralCodeStore(
    (state) => state.allReferralCodes
  );
  const consumer = useConsumerStore((state) => state.consumer);

  function findExcelSheetIdByRegion(regionName) {
    for (const referralCode of allReferralCodes) {
      if (referralCode["pdtf-region"] === regionName) {
        return referralCode["pdtf-excelsheetid"];
      }
    }
    return null;
  }

  const authenticatedUser = {
    externalUserId: consumer.id,
    authenticationId: user?.sub,
  };

  useEffect(() => {
    const valuesToLog = state.valuesToLog;

    const regionName = consumer.regionName;

    const excelSheetId = findExcelSheetIdByRegion(regionName);
    const organizationId = cdtMapping.orgid;

    // networkService
    //   .post(`/api/sheets-integration`, {
    //     organizationId,
    //     spreadsheetId: excelSheetId,
    //     sheetName: "Sheet1",
    //     // primaryKeyColumnIndex: 0,
    //     endingColumn: "Z",
    //     dataToStore: [valuesToLog],
    //   })
    //   .then((res) => {
    //     websocketLoggerService.sendMessage({
    //       ...authenticatedUser,
    //       eventType: "API request: POST /api/sheets-integration",
    //       eventSubType: `sheets-integration,add cancel appointment logs`,
    //       eventData:
    //         "successfully added logs to sheets-integration, navigate to /cancel-appointment-success",
    //     });
    //   })
    //   .catch((err) => {
    //     loggerService.error(err);
    //     websocketLoggerService.sendMessage({
    //       ...authenticatedUser,
    //       eventType: "API request: POST /api/sheets-integration",
    //       eventSubType: `sheets-integration,add cancel appointment logs`,
    //       eventData: `could not add logs to sheets-integration, Error: ${JSON.stringify(
    //         err
    //       )}`,
    //     });
    //   });
  }, []);

  return (
    <Backdrop>
      <div className="flex flex-col px-28 py-10">
        <div className="text-6xl -ml-3">👍</div>
        <div className="text-2xl max-lg:text-xl mt-4 font-extrabold">
          Your booking has been cancelled
        </div>
        <div className="mt-4">
          If you change your mind, simply reach out to us at <br />
          <a
            href="mailto:rippleadmin@sheareshealthcare.com.sg"
            className="text-primary-800">
            {" "}
            support@azodha.com
          </a>
        </div>
      </div>
    </Backdrop>
  );
};

export default CancelBookingSuccess;
